<!--
 * @Author: your name
 * @Date: 2022-03-01 15:51:50
 * @LastEditTime: 2022-04-01 14:08:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-shoping\src\components\ctoast\index.vue
-->
<template>
  <div class="toast">
    <div class="center">
      <img src="@/assets/images/index/open.png" alt="" class="img">
      <div class="text">请使用微信扫码打开</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
}
</script>
<style lang="less" scoped>
.toast{
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  .center{
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .img{
      display: block;
      width: 100%;
    }
    .text{
      font-size: 14px;
      text-align: center;
      color: #aaaaaa;
    }
  }
}
</style>